import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reportsApi from "../../api/reportsApi";
import { useAppContext } from "../../contexts/AppContext";
import DeleteAlert from "../../Components/DialogAlert/DeleteAlert";
import ReportDetailsTable from "../../Components/ReportsComponents/ReportDetailsTable";
import Typography from '@mui/material/Typography'
import routes from "../../Services/routes";

export default function ReportDetailsScreen() {
	const navigate = useNavigate();
	const { isAlertOpen, setIsAlertOpen, user, isAdmin, users, freelancers } = useAppContext();
	const { state: report } = useLocation();
	const [reportUsers, setReportUsers] = useState(report?.user)
	const [reportFreelancers, setReportFreelancers] = useState(report?.freelancers)
	const [loadingSpinner, setLoadingSpinner] = useState({})
	const [selected, setSelected] = useState("")
	const [selectedFreelancer, setSelectedFreelancer] = useState("")
	const created = moment(report?.created_at).format("DD MMM YY, HH:mm");
	const updated = report?.updated_at ? moment(report?.updated_at).format("DD MMM YY, HH:mm") : null;
	const [open, setOpen] = useState(false);
	const [openFreelancerSelect, SetOpenFreelancerSelect] = useState(false);

	const closeAndNotify = () => {
		toast("Report Deleted", {
			position: "top-right",
			autoClose: 1000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			theme: "dark",
		});
		setTimeout(() => {
			navigate(-1);
		}, 2000);
	};
	const handleClickOpen = () => {
		setIsAlertOpen(true);
	};

	const handleClose = () => {
		setIsAlertOpen(false);
	};

	const handleReportDelete = async (id) => {
		setIsAlertOpen(false);
		try {
			await reportsApi.deleteItem(id);
		} catch (error) {
			toast.error("Something went wrong, try again.", {
				position: "top-right",
				autoClose: false,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
			console.log("EROOR DELETE REPORT", error);
		} finally {
			closeAndNotify("Report deleted");
		}
	};

	const handleUserDelete = async (user) => {
		setLoadingSpinner({ id: user.id, loading: true })
		let originalReportUsers = [...reportUsers]
		try {
			await reportsApi.removeUserFromReport(report?.id, user?.id)
			const response = await reportsApi.findAll()
			const refreshedReport = response.filter(r => r.id === report.id)
			setReportUsers(refreshedReport[0].user)
		} catch (error) {
			setReportUsers(originalReportUsers)
			console.log("EROOORRUSERDELETEFROMREPORT", error)
		}
		setLoadingSpinner({ id: "", loading: false })
	}

	const handleAddUser = async () => {
		try {
			await reportsApi.addUserToReport(report?.id, selected)
			const response = await reportsApi.findAll()
			const refreshedReport = response.filter(r => r.id === report.id)
			setReportUsers(refreshedReport[0].user)
			setOpen(false)
		} catch (error) {
			console.log("EROOORRUSERADDROMREPORT", error)
		}
	}

	const handleAddFreelancer = async () => {
		try {
			await reportsApi.addFreelancerToReport(report?.id, selectedFreelancer)
			const response = await reportsApi.findAll()
			const refreshedReport = response.filter(r => r.id === report.id)
			setReportFreelancers(refreshedReport[0].freelancers)
			SetOpenFreelancerSelect(false)
		} catch (error) {
			console.log("EROOORRUSERADDROMREPORT", error)
		}
	}


	const handleFreelancerDelete = async (freelancer) => {
		setLoadingSpinner({ id: freelancer.id, loading: true })
		let originalReportFreelancers = [...reportFreelancers]
		try {
			await reportsApi.removeFreelancerFromReport(report?.id, freelancer?.id)
			const response = await reportsApi.findAll()
			const refreshedReport = response.filter(r => r.id === report.id)
			setReportFreelancers(refreshedReport[0].freelancers)
		} catch (error) {
			setReportFreelancers(originalReportFreelancers)
			console.log("EROOORRUSERDELETEFROMREPORT", error)
		}
		setLoadingSpinner({ id: "", loading: false })
	}

	return (
		<>
			<ToastContainer />
			<Box sx={{ flexGrow: 1, mt: 5, mx: 2 }}>
				<ButtonGroup
					sx={{ mb: 2 }}
					variant="contained"
					aria-label="outlined primary button group"
				>
					<Button onClick={() => navigate(-1)}>
						<ArrowBackIosIcon />
					</Button>
					<Button onClick={() => navigate(routes.editReport, { state: report })}>
						<EditIcon />
					</Button>
					<Button onClick={() => handleClickOpen()}>
						<DeleteIcon />
					</Button>
				</ButtonGroup>
				<Typography
					variant="overline"
					color="initial"
					sx={{ ml: 4 }}>
					Report Id : <span style={{ color: "red" }}>{report.id}</span> - created at : <span style={{ color: "red" }}>{created}</span> - Updated at : <span style={{ color: "red" }}>{updated != null ? updated : "Not Updated Yet"}</span></Typography>

				<ReportDetailsTable
					handleAddUser={handleAddUser}
					handleAddFreelancer={handleAddFreelancer}
					selected={selected}
					setSelected={setSelected}
					loadingSpinner={loadingSpinner}
					isAdmin={isAdmin}
					user={user}
					freelancers={freelancers}
					reportUsers={reportUsers}
					reportFreelancers={reportFreelancers}
					users={users}
					handleUserDelete={handleUserDelete}
					report={report}
					open={open}
					setOpen={setOpen}
					SetOpenFreelancerSelect={SetOpenFreelancerSelect}
					openFreelancerSelect={openFreelancerSelect}
					selectedFreelancer={selectedFreelancer}
					setSelectedFreelancer={setSelectedFreelancer}
					handleFreelancerDelete={handleFreelancerDelete}

				/>
			</Box>

			<DeleteAlert
				open={isAlertOpen}
				title="Delete Report"
				data={report}
				handleDelete={() => handleReportDelete(report.id)}
				clickClose={handleClose}
			/>
		</>
	);
}



