const formShortcuts = {
	customers: ["BIS", "ROCHE"],
	managers:
		[
			{ id: 1, name: "Oliver Kiefer", tag: "OK", company: "BIS" },
			{ id: 2, name: "Timon Elinger", tag: "TE", company: "BIS" },
			{ id: 3, name: "Danijel ", tag: "DR", company: "BIS" },
			{ id: 4, name: "Nevena Kuric", tag: "NK", company: "ROC" },
			{ id: 5, name: "Sascha Martschinke", tag: "SM", company: "ROC" },
			{ id: 6, name: "Tanja Pukrow", tag: "TP", company: "ROC" },
			{ id: 7, name: "Anina Lehner", tag: "AL", company: "ROC" },
			{ id: 8, name: "Alex Mathias", tag: "AM", company: "BIS" }
		],
	locations: ["Saal A", "Saal B", "Saal C", "Saal D", "Saal E", "Saal M", "LA71", "Bau1", "218A", "218B", "218C", "Studio", "QRST", "NOP"],
}
export default formShortcuts