import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge, Box, Checkbox, Container, FormControl, FormHelperText, FormLabel } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import reportsApi from "../../api/reportsApi";
import { useAppContext } from "../../contexts/AppContext";
// import FormSelect from "../../Components/FomsComponents/FormSelect";
import FormDatePicker from "../../Components/FomsComponents/FormDatePicker";
import FormTimePicker from "../../Components/FomsComponents/FormTimePicker";
import FormTitle from "../../Components/FomsComponents/FormTitle";
import FormButton from "../../Components/FomsComponents/FormButton";
import FormTextField from "../../Components/FomsComponents/FormTextField";
import FormCheckbox from "../../Components/FomsComponents/FormCheckBox";
import ReportSwitch from "../../Components/AppButton.jsx/ReportSwitch";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import MultipleSelectUsers from "../../Components/FomsComponents/MultipleSelectUsers";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FormShortcut from "../../Services/formShortcut";
import MultipleSelectFreelancers from "../../Components/FomsComponents/MultipleSelectFreelancers";
import Button from '@mui/material/Button';
import routes from "../../Services/routes";
import projectsApi from "../../api/projectsApi";
import FormSelectSearch from "../../Components/FomsComponents/FormSelectSearch";

const NewReportScreen = () => {
	const {
		users,
		getUsers,
		projects,
		getProjects,
		showBreakInput,
		setShowBreakInput,
		showPrepInput,
		setShowPrepInput,
		showPostProdInput,
		setShowPostProdInput,
		user,
		freelancers,
		getFreelancers,
		doneStatus,
		setDoneStatus,
	} = useAppContext();

	const navigate = useNavigate();
	const filteredProjectsByStatus = projects?.filter(project => project?.status !== "Done" && project?.status !== "Cancelled")
	const location = useLocation();

	const handleDoneStatus = () => {
		setDoneStatus(!doneStatus)
	}

	const addReport = async (values) => {
		try {
			await projectsApi.handleProjectStatusFromReport(formik?.values?.project.id, doneStatus)
			await reportsApi.createReport(values);
			toast("Report Created", {
				position: "top-right",
				autoClose: 1000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
			setTimeout(() => {
				navigate(routes.reports);
			}, 2000);
			setDoneStatus(false)
		} catch (error) {
			formik.setSubmitting(false);
			console.log("EROOORPOSTREPORT", error.response);
			toast.error(error.response.data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
				theme: "dark",
			});
			setDoneStatus(false)

		}
	};

	const formik = useFormik({
		initialValues: {
			date: null,
			getin: null,
			start: null,
			end: null,
			expense: "",
			remarks: "",
			// Pour automatiquement reprendre le projet crée dans le selct, ne marche pas pour le moment, trouver une nouvelle solution
			project: location?.state?.fromm === routes.newProject ? location?.state?.createdProject?.id : "",
			location: "",
			contact: "",
			customer: "",
			break: null,
			breakEnd: null,
			postProd: null,
			postProdEnd: null,
			travelCost: false,
			processed: false,
			user: [],
			freelancers: [],
			prepStart: null,
			prepEnd: null,
			isOvernightShift: false,
		},
		validationSchema: Yup.object().shape({
			project: Yup.object().nullable(),
			location: Yup.string().nullable(),
			customer: Yup.string().nullable(),
			date: Yup.string().required("Date is required").nullable(),
			getin: Yup.string().nullable(),
			start: Yup.string().required("Start is required").nullable(),
			end: Yup.string().required("End is required").nullable(),
			expense: Yup.string().nullable(),
			remarks: Yup.string(),
			break: Yup.string().nullable(),
			breakEnd: Yup.string().nullable(),
			postProd: Yup.string().nullable(),
			postProdEnd: Yup.string().nullable(),
			travelCost: Yup.boolean().nullable(),
			processed: Yup.boolean().nullable(),
			contact: Yup.string().nullable(),
			user: Yup.array().nullable(),
			freelancers: Yup.array().nullable(),
			prepStart: Yup.string().nullable(),
			prepEnd: Yup.string().nullable(),
			isOvernightShift: Yup.boolean().nullable(),
		}),

		onSubmit: (values) => {
			// console.log(values.project.id);
			addReport(values);
		},
	});
	const handleBreakSwitch = () => {
		setShowBreakInput(!showBreakInput);
		formik.setFieldValue("break", null);
		formik.setFieldValue("breakEnd", null);
	};
	const handlePostProdSwitch = () => {
		setShowPostProdInput(!showPostProdInput);
		formik.setFieldValue("postProd", null);
		formik.setFieldValue("postProdEnd", null);
	};
	const handlePrepSwitch = () => {
		setShowPrepInput(!showPrepInput);
		formik.setFieldValue("prepStart", null);
		formik.setFieldValue("prepEnd", null);
	};
	useEffect(() => {
		getProjects();
		getUsers();
		getFreelancers()
	}, []);
	return (
		<>
			<ToastContainer />
			<Box
				component="main"
				sx={{
					alignItems: "center",
					display: "flex",
					flexGrow: 1,
					minHeight: "100%",
				}}
			>
				<Container maxWidth="sm">
					<form onSubmit={formik.handleSubmit}>
						<FormTitle
							title={"New Report"}
							action={() => {
								navigate(-1);
							}}
						/>

						<MultipleSelectUsers users={users} handleSelect={formik?.setFieldValue} user={user} helper={"You weren't alone? Add a colleague here."} />
						<MultipleSelectFreelancers freelancers={freelancers} handleSelect={formik?.setFieldValue} user={user} helper={"You were with freelancers? Add them. *You can create an entry for a new freelancer."} />

						<LocalizationProvider dateAdapter={AdapterMoment}>

							<Stack direction="row" spacing={2} alignItems="center" justifyContent='center'>
								<FormSelectSearch
									error={Boolean(formik?.touched?.project && formik?.errors?.project)}
									label="Project"
									value={formik?.values?.project}
									onChange={(value) => formik?.setFieldValue("project", value)}
									data={filteredProjectsByStatus}
									helper={formik?.touched?.project ? formik?.errors?.project : "Choose a project. *Only unclosed projects are available for selection.  Or..."}
								/>
								{location?.state?.createdProject &&
									<Stack direction="column" spacing={2} alignItems="center" justifyContent='center'>
										<Chip label="Use last created project" onClick={() => formik.setFieldValue("project", location?.state?.createdProject)} />
										<Chip label="Cancel" onClick={() => formik.setFieldValue("project", "")} />
									</Stack>}
							</Stack>

							<Box sx={{ display: "flex", my: 1, flexDirection: "column" }}>
								<Button onClick={() => navigate(routes.newProject, { state: { from: routes.newReport } })} variant="outlined" color="success">Create project</Button>
								<FormHelperText sx={{ ml: 2 }}>
									Create your project from Kerio's entry.
								</FormHelperText>
							</Box>

							<Stack direction="row" spacing={2} alignItems="center">
								<FormDatePicker
									value={formik?.values?.date}
									onChange={(value) => formik.setFieldValue("date", value)}
									onError={() => formik.setErrors("date", "Date Is Required")}
									onBlur={formik?.handleBlur("date")}
									helper={formik?.touched?.date && formik?.errors?.date}
									error={Boolean(formik?.touched?.date && formik?.errors?.date)}
								/>
								<Chip icon={<CalendarMonthIcon />} label="Today" onClick={() => formik.setFieldValue("date", new Date())} />
							</Stack>

							<FormTimePicker
								error={Boolean(formik?.touched?.getin && formik?.errors?.getin)}
								label="Get In Time"
								onError={() => formik?.setErrors("getin", "Get In Is Required")}
								value={formik?.values?.getin}
								onChange={(value) => formik?.setFieldValue("getin", value?._d)}
								onBlur={formik?.handleBlur("getin")}
								helper={formik?.touched?.getin && formik?.errors?.getin}
							/>

							<FormTimePicker
								error={Boolean(formik?.touched?.start && formik?.errors?.start)}
								label="Start Time"
								onError={() => formik?.setErrors("start", "Start Is Required")}
								value={formik?.values?.start}
								onChange={(value) => formik?.setFieldValue("start", value?._d)}
								onBlur={formik?.handleBlur("start")}
								helper={formik?.touched?.start && formik?.errors?.start}
							/>
							<Stack direction="row" spacing={2} alignItems="center">
								<FormTimePicker
									error={Boolean(formik?.touched?.end && formik?.errors?.end)}
									label="End Time"
									onError={() => formik?.setErrors("end", "End Is Required")}
									value={formik?.values?.end}
									onChange={(value) => formik?.setFieldValue("end", value?._d)}
									onBlur={formik?.handleBlur("end")}
									helper={formik?.touched?.end && formik?.errors?.end}
								/>
								<Chip icon={<AccessTimeIcon />} label="Now" onClick={() => formik.setFieldValue("end", Date.now())} />
							</Stack>

							<ReportSwitch
								checked={showBreakInput}
								onChange={handleBreakSwitch}
								label="Break"
							/>

							{showBreakInput && (
								<>
									<FormTimePicker
										error={Boolean(formik?.touched?.break && formik?.errors?.break)}
										label="Set Break Time"
										onError={() =>
											formik?.setErrors("break", "Break Is a time format")
										}
										value={formik?.values?.break}
										onChange={(value) =>
											formik?.setFieldValue("break", value?._d)
										}
										onBlur={formik?.handleBlur("break")}
										helper={formik?.touched?.break && formik?.errors?.break}
									/>

									<FormTimePicker
										error={Boolean(
											formik?.touched?.breakEnd && formik?.errors?.breakEnd
										)}
										label="Set Break End Time"
										onError={() =>
											formik.setErrors("breakEnd", "Break End Is a time format")
										}
										value={formik?.values?.breakEnd}
										onChange={(value) =>
											formik?.setFieldValue("breakEnd", value?._d)
										}
										onBlur={formik?.handleBlur("breakEnd")}
										helper={formik?.touched?.breakEnd && formik?.errors?.breakEnd}
									/>

								</>
							)}

							<ReportSwitch
								checked={showPostProdInput}
								onChange={handlePostProdSwitch}
								label="Post Production"
							/>

							{showPostProdInput && (
								<>
									<FormTimePicker
										error={Boolean(
											formik?.touched?.postProd && formik?.errors?.postProd
										)}
										label="Set Post-Prod Start"
										onError={() =>
											formik.setErrors("postProd", "Post-Prod Is a time format")
										}
										value={formik?.values?.postProd}
										onChange={(value) =>
											formik.setFieldValue("postProd", value?._d)
										}
										onBlur={formik?.handleBlur("postProd")}
										helper={formik?.touched?.postProd && formik?.errors?.postProd}
									/>
									<FormTimePicker
										error={Boolean(
											formik?.touched?.postProdEnd && formik?.errors?.postProdEnd
										)}
										label="Set Post-Prod End"
										onError={() =>
											formik.setErrors(
												"Post-Prod End",
												"Post-Prod Is a time format"
											)
										}
										value={formik?.values?.postProdEnd}
										onChange={(value) =>
											formik?.setFieldValue("postProdEnd", value?._d)
										}
										onBlur={formik?.handleBlur("postProdEnd")}
										helper={
											formik?.touched?.postProdEnd && formik?.errors?.postProdEnd
										}
									/>
								</>
							)}

							<ReportSwitch
								checked={showPrepInput}
								onChange={handlePrepSwitch}
								label="Preparation-phase"
							/>

							{showPrepInput && (
								<>
									<FormTimePicker
										error={Boolean(
											formik?.touched?.prepStart && formik?.errors?.prepStart
										)}
										label="Set Preparation Start"
										onError={() =>
											formik.setErrors("prepStart", "Post-Prod Is a time format")
										}
										value={formik?.values?.prepStart}
										onChange={(value) =>
											formik.setFieldValue("prepStart", value?._d)
										}
										onBlur={formik?.handleBlur("prepStart")}
										helper={formik?.touched?.prepStart && formik?.errors?.prepStart}
									/>
									<FormTimePicker
										error={Boolean(
											formik?.touched?.prepEnd && formik?.errors?.prepEnd
										)}
										label="Set Preparation End"
										onError={() =>
											formik.setErrors(
												"prepEnd",
												"Post-Prod Is a time format"
											)
										}
										value={formik?.values?.prepEnd}
										onChange={(value) =>
											formik?.setFieldValue("prepEnd", value?._d)
										}
										onBlur={formik?.handleBlur("prepEnd")}
										helper={
											formik?.touched?.prepEnd && formik?.errors?.prepEnd
										}
									/>
								</>
							)}
						</LocalizationProvider>

						<FormTextField
							error={Boolean(formik.touched.expense && formik.errors.expense)}
							helperText={formik.touched.expense && formik.errors.expense}
							label="Extra Expense"
							onBlur={formik.handleBlur("expense")}
							onChange={formik.handleChange("expense")}
							value={formik.values.expense}
						/>


						<Stack direction="row" spacing={2} alignItems="center">
							<FormTextField
								error={Boolean(formik.touched.customer && formik.errors.customer)}
								helperText={formik.touched.customer && formik.errors.customer}
								label="Customer"
								onBlur={formik.handleBlur("customer")}
								onChange={formik.handleChange("customer")}
								value={formik.values.customer}
							/>
							{FormShortcut.customers.map(c => <Chip key={c} label={c} onClick={() => formik.setFieldValue("customer", c)} />)}
						</Stack>


						<Stack sx={{ mt: 2 }} direction="column" spacing={1} alignItems="center" >
							<Stack direction="row" alignItems="center" spacing={3.5} width={"100%"} flexWrap="wrap" sx={{ my: 2.5 }}>

								{FormShortcut?.managers?.map(m =>
									<Badge
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'right',
										}}
										color="primary"
										key={m.id}
										badgeContent={m.company}
										sx={{ mb: 3 }}
									>
										<Chip sx={{ m: 0.5 }} label={m?.name} onClick={() => formik.setFieldValue("contact", m?.name)} />
									</Badge>)}
							</Stack>

							<FormTextField
								error={Boolean(formik.touched.contact && formik.errors.contact)}
								helperText={formik.touched.contact && formik.errors.contact}
								label="Contact"
								onBlur={formik.handleBlur("contact")}
								onChange={formik.handleChange("contact")}
								value={formik.values.contact}
							/>
						</Stack>
						<Stack sx={{ my: 2 }} direction="column" spacing={1} alignItems="center" >
							<Stack direction="row" alignItems="center" spacing={0.5} width={"100%"} flexWrap="wrap" sx={{ my: 2.5 }}>

								{FormShortcut?.locations?.map(l => <Chip sx={{ m: 0.5 }} key={l} label={l} onClick={() => formik.setFieldValue("location", l)} />)}
							</Stack>

							<FormTextField
								error={Boolean(formik.touched.location && formik.errors.location)}
								helperText={formik.touched.location && formik.errors.location}
								label="Location"
								onBlur={formik.handleBlur("location")}
								onChange={formik.handleChange("location")}
								value={formik.values.location}
							/>
						</Stack>

						<FormTextField
							multiline
							error={Boolean(formik.touched.remarks && formik.errors.remarks)}
							helperText={formik.touched.remarks && formik.errors.remarks}
							label="Remarks"
							onBlur={formik.handleBlur("remarks")}
							onChange={formik.handleChange("remarks")}
							value={formik.values.remarks}
						/>
						<FormCheckbox
							error={Boolean(
								formik.touched.travelCost && formik.errors.travelCost
							)}
							helperText={formik.touched.travelCost && formik.errors.travelCost}
							label="Travel Cost"
							onBlur={formik.handleBlur("travelCost")}
							onChange={formik.handleChange("travelCost")}
							value={formik.values.travelCost}
						/>
						{
							formik?.values?.project &&
							<FormControl fullWidth>
								<FormLabel>Done</FormLabel>
								<Checkbox onChange={() => handleDoneStatus()} checked={doneStatus} />

								<FormHelperText sx={{ ml: 2 }}>
									You finished your mission ? Check the Done box.
								</FormHelperText>
							</FormControl>
						}

						{user?.roles?.includes("ROLE_MANAGER") && <FormCheckbox
							error={Boolean(
								formik.touched.processed && formik.errors.processed
							)}
							helperText={formik.touched.processed && formik.errors.processed}
							label="Processed"
							onBlur={formik.handleBlur("processed")}
							onChange={formik.handleChange("processed")}
							value={formik.values.processed}
						/>}

						<FormButton text={"Create Report"} disabled={formik?.isSubmitting} />
					</form>
				</Container>
			</Box>
		</>
	);
};

export default NewReportScreen;
